import { initializeApp } from 'firebase/app';

const firebaseConfig = {
    apiKey: "AIzaSyAhVVG2KswKYuwIiGG5ko8XRc9ZKIGr5FE",
    authDomain: "kongeodds-6c96d.firebaseapp.com",
    projectId: "kongeodds-6c96d",
    storageBucket: "kongeodds-6c96d.appspot.com",
    messagingSenderId: "3737862544",
    appId: "1:3737862544:web:d5c4b39657d87f6d183315",
    measurementId: "G-QQFWV0LK26"
};

export const app = initializeApp(firebaseConfig);