import React, { useState } from 'react';
import { createUserWithEmailAndPassword, GoogleAuthProvider, signInWithPopup } from "firebase/auth";

// ... rest of your code ...

function CreateAccountPage() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const handleCreateAccount = async (event) => {
        event.preventDefault();
        try {
            await createUserWithEmailAndPassword(email, password);
            // handle user
        } catch (error) {
            console.error(error);
        }
    };

    const handleCreateAccountWithGoogle = async () => {
        try {
            const provider = new GoogleAuthProvider();
            await signInWithPopup(provider);
            // handle user
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div>
            <form onSubmit={handleCreateAccount}>
                <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email" required />
                <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password" required />
                <button type="submit">Create Account</button>
            </form>
            <button onClick={handleCreateAccountWithGoogle}>Create Account with Google</button>
        </div>
    );
}

export default CreateAccountPage;