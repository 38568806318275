import React, { useState } from 'react';
import { getAuth, GoogleAuthProvider, signInWithPopup, signOut } from 'firebase/auth';
import { app } from '../firebaseConfig';
import { Link } from "react-router-dom";

const auth = getAuth(app);
const provider = new GoogleAuthProvider();

const Navbar = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    const handleLogin = async () => {
        try {
            await signInWithPopup(auth, provider);
            setIsLoggedIn(true);
        } catch (error) {
            console.error(error);
        }
    };

    const handleLogout = async () => {
        try {
            await signOut(auth);
            setIsLoggedIn(false);
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <nav>
            <div>
                <h1>Kongeodds</h1>
            </div>
            <div>
                {isLoggedIn ? (
                    <>
                        <button onClick={handleLogout}>Log ud</button>
                        <button>Konto</button>
                    </>
                ) : (
                    <>
                        <Link to="/create-account">
                            <button>Opret Konto</button>
                        </Link>
                        <button onClick={handleLogin}>Login</button>
                    </>
                )}
            </div>
        </nav>
    );
};

export default Navbar;